<template>
	<div id="manualDealAdd" class="flex-between">
		<div class="w-2/5 mr-2">
			<manualDealSearchCard :pData="hottist" cName="hottistSearchCard" seq="1" />
			<b-card>
				<tb
					@changePage="hottist.changePage"
					@click="hottist.selectItem"
					:child="hottist.child"
					:inf="hottist.inf"
					:res="hottist.res"
					cName="hottistTable"
					limit="20"
					selectable
				/>
			</b-card>
		</div>
		<div class="w-2/5 mx-2">
			<manualDealSearchCard :pData="productGroup" cName="productGroupSearchCard" seq="2" />
			<b-card>
				<tb
					@changePage="productGroup.changePage"
					@click="productGroup.selectItem"
					:child="productGroup.child"
					:inf="productGroup.inf"
					:res="productGroup.res"
					cName="productGroupTable"
					limit="20"
					selectable
				/>
			</b-card>
		</div>
		<div class="w-1/5 ml-2">
			<b-card>
				<h4 class="mb-3" v-text="'3. 기간 설정'" />
				<p>[해당 상품의 딜 불가기간]</p>
				<p v-show="!restrictedDateList.length">없음</p>
				<div v-show="restrictedDateList.length">
					<p v-for="v in restrictedDateList">● {{ v.startDate }} ~ {{ v.endDate }}</p>
				</div>
				<h6>딜 시작일</h6>
				<datePicker model="startDate" />
				<h6 class="mt-4">딜 종료일</h6>
				<datePicker model="endDate" />

				<h6 class="mt-4">딜 수수료 추가변경(딜 수락/시작일 전 입력가능)</h6>
				<div class="flex">
					<b-form-input
						v-model.trim="chargeAdd"
						name="title"
						placeholder="변경할 딜 수수료를 입력해주세요"
						@keyup="chargeAdd = numFormat(chargeAdd)"
					/>
					<p class="lh-9 ml-1">%</p>
				</div>

				<b-button
					class="w-1/1 mt-4"
					@click="addManualDeal(true)"
					variant="info"
					v-text="getTemporaryBtnText()"
				/>
				<b-button class="w-1/1 mt-2" @click="addManualDeal(false)" v-text="'바로 등록하기'" />
			</b-card>
		</div>
	</div>
</template>

<script>
import manualDealSearchCard from 'pages/manualDealAddSearchCard'
import manualDealMixIn from 'pages/manualDealMixIn'

let ths
export default {
	props: { idx: { default: '' } },
	mixins: [manualDealMixIn],
	components: { manualDealSearchCard },
	data() {
		const hottist = {
				title: '핫티스트',
				searchKeyOpts: [
					{ label: '인스타아이디', value: 'currentInstaId' },
					{ label: '이름', value: 'memberName' },
					{ label: '휴대폰', value: 'phone' },
					{ label: '핫트아이디', value: 'hottId' },
				],
				apiLink: '/hottistSelect/hottistList',
				defaultSearchData: {
					saveStatus: 'Y',
					userType: 'A',
					isExcel: 0,
					paging: getPaging(0, 20),
					orderBy: getOrder('registerTime'),
				},
				res: [],
				formatting(res) {
					//서버에서 값 조회 후 프론트에서 보여주는 포맷에 맞게 정리
					res.list = res.list.map(v => {
						v.registerTime = v.registerTime.substr(0, 10)
						v.child = false
						return v
					})
					return res
				},
				primarySearch(hottId) {
					//고유키를 입력 받아 바로 검색 처리.
					const searchCard = ths.hottistSearchCard
					searchCard.opt = {
						searchKeyName: {
							label: '핫트아이디',
							value: 'hottId',
						},
						searchKeyword: hottId,
					}

					const searchRes = searchCard.search('EQUAL')
					if (searchRes)
						searchRes.then(() => {
							//검색 결과 등록한 뒤 검색 결과가 있으면 해당 item을 선택 상태로 한다.
							const data = hottist.res.list
							//너무 길다...
							if (data.length) {
								//EQUAL로 변경했기 때문에 루프 돌 필요가 없음
								hottist.selectedItem = data[0]
								ths.hottistTable.selectedRow = data[0]
							}
						})
				},
				changePage(page = 1) {
					ths.hottistSearchCard.changePage(page)
				},
				inf: [
					{ title: '인스타아이디', key: 'currentInstaId', size: 12 },
					{ title: '이름', key: 'name', size: 10 },
					{ title: '휴대폰 번호', key: 'phone', size: 12 },
					{ title: '핫트아이디', key: 'hottId', size: 12 },
					{ title: '가입일', key: 'registerTime', size: 16 },
				],
				selectedItem: '',
				selectItem(v, i) {
					//핫티스트 선택
					//ths.hottistTable.bTable.selectedRows[i] = true
					//v._showDetails = !v._showDetails
					hottist.child.deleteSelectedItem()

					//기존에 열린 다른 자식 항목은 닫아 달라고 함
					hottist.res.list.map(another => {
						if (another != v) another.child = false
					})

					hottist.selectedItem = v
					if (!v.child) {
						//자식(기존 딜 신청 목록)이 없는 상태면 다시 받아오고 있는 상태면 제거
						const data = {
							hottId: v.hottId,
							//hottId 제외 모두 고정값
							isClose: true,
							isRealTime: false,
							isExcel: 0,
							paging: getPaging(0, 20),
							orderBy: getOrder('startDate'),
						}
						v.child = {
							lastOpt: data,
							res: [],
						}
						hottist.child.changePage(v)
					} else v.child = false
					ths.dealTypeValue = null
					ths.checkEncore()
				},
				//여기부턴 자식 테이블(기존 딜)에 대한 설정
				child: {
					inf: [
						{ title: 'idx', key: 'idx', size: 8 },
						{ title: '브랜드명', key: 'brandName', size: 18 },
						{ title: '상품그룹명', key: 'productGroupName', size: 22 },
						{ title: '시작일', key: 'startDt', size: 12 },
						{ title: '종료일', key: 'endDt', size: 12 },
						{ title: '타입', key: 'productType', size: 12 },
					],
					prependItem: [],
					tbClass: 'w-11/12 mx-auto',
					changePage(targetHottist, page = 1) {
						//기존 딜 리스트를 조회한다
						const data = targetHottist.child.lastOpt
						data.paging.pageNo = page - 1

						return postApi('/hottistSelect/dealList', data).then(res => {
							//받아오는 데 성공했으면 포맷 조정
							res.list = [
								...copyVar(ths.hottist.child.prependItem),
								...res.list.map(r => {
									r.startDt = r.startDt.substr(0, 10)
									r.endDt = r.endDt.substr(0, 10)
									r.productType = ths.getProductType(r.productType)
									return r
								}),
							]
							targetHottist.child.title = `${targetHottist.name}의 딜 진행 이력`
							targetHottist.child.res = res
							return res
						})
					},
					selectable: 1,
					selectedItem: '',
					selectItem(v, index) {
						//핫티스트의 기존딜 선택
						if (index) {
							hottist.child.selectedItem = v
							ths.dealTypeValue = v.dealTypeValue ?? null
							if (v.productGroupIdx) productGroup.primarySearch(v.productGroupIdx)
						}
					},
					deleteSelectedItem() {
						hottist.child.selectedItem = ''
					},

					nav: 'none',
				},
			},
			productGroup = {
				title: '상품그룹',
				searchKeyOpts: [
					{ label: '상품그룹명', value: 'productGroupName' },
					{ label: 'idx', value: 'productGroupIdx' },
					{ label: '브랜드명', value: 'brandName' },
					{ label: '노출등급', value: 'productGroupClass' },
				],
				apiLink: '/productGroupSelect/productGroupList',
				defaultSearchData: {
					paging: getPaging(0, 20),
					orderBy: getOrder('productGroupIdx'),
				},
				res: [],
				formatting(res) {
					//서버에서 값 조회 후 프론트에서 보여주는 포맷에 맞게 정리
					res.list = res.list.map(v => {
						v.enrollDate = v.enrollDate.substr(0, 10)
						v.productType = ths.getProductType(v.productType)
						v.child = false
						if (v.productGroupClass == 'z') v.trClass = 'text-gray-light'
						v.essentialStatus = v.isEssential ? 'O' : 'X'
						return v
					})
					return res
				},
				primarySearch(productGroupIdx, productOptionSeq = false) {
					//고유키를 입력 받아 바로 검색 처리.
					ths.productGroupSearchCard.opt = {
						searchKeyName: {
							label: 'idx',
							value: 'productGroupIdx',
						},
						searchKeyword: productGroupIdx,
					}

					const searchRes = ths.productGroupSearchCard.search('EQUAL')
					if (searchRes)
						searchRes.then(() => {
							//검색 결과 등록한 뒤 검색 결과가 있으면 해당 item을 선택 상태로 한다.
							const list = productGroup.res.list
							if (list.length) {
								ths.productGroupTable.selectedRow = list[0]
								productGroup.selectedItem = list[0]

								//협찬품 받아오기 시작
								const childSearchData = {
									groupIdx: productGroupIdx,
								}
								list[0].child = {
									lastOpt: childSearchData,
									res: [],
								}

								productGroup.child.getChildData(list[0], childSearchData).then(res => {
									let target = false
									if (productOptionSeq === 1977) target = productGroup.child.prependItem[0]
									else if (productOptionSeq === 0) target = productGroup.child.prependItem[1]
									else if (productOptionSeq) {
										//협찬품도 선택하도록 들어왔다면
										let filter = res.filter(v => v.seqNo == productOptionSeq)
										if (filter.length) target = filter[0]
									}

									if (target) {
										productGroup.child.selectedItem = target
										ths.productGroupTable.childTb.selectedRow = target
									}
								})
								ths.checkEncore()
							}
						})
				},
				changePage(page = 1) {
					ths.productGroupSearchCard.changePage(page)
				},

				inf: [
					{ title: 'idx', key: 'productGroupIdx', size: 4 },
					{ title: '브랜드명', key: 'brandName', size: 10 },
					{ title: '상품그룹명', key: 'productGroupName', size: 20 },
					{ title: '노출 등급', key: 'productGroupClass', size: 4 },
					{ title: '기본 수수료', key: 'fee', size: 4 },
					{ title: '타입', key: 'productType', size: 6 },
					{ title: '앵콜 필수', key: 'essentialStatus', size: 3 },
					{ title: '등록일', key: 'enrollDate', size: 12 },
				],
				selectedItem: '',
				selectItem(v, i) {
					//상품그룹 선택
					productGroup.child.deleteSelectedItem()

					//기존에 열린 다른 자식 항목은 닫아 달라고 함
					productGroup.res.list.map(another => {
						if (another != v) another.child = false
					})

					productGroup.selectedItem = v
					if (!v.child) {
						const data = {
							groupIdx: v.productGroupIdx,
						}
						v.child = {
							lastOpt: data,
							res: [],
						}
						productGroup.child.getChildData(v, data)
						//이건 페이징이 없이 내려온다
					} else v.child = false
					ths.dealTypeValue = null
					ths.checkEncore()
				},
				//여기부턴 자식 테이블(협찬품)에 대한 설정
				child: {
					inf: [
						{ title: '상품 코드', key: 'productNum', size: 8 },
						{ title: '상품명', key: 'productName', size: 16 },
						{ title: '옵션1', key: 'productOption1', size: 10 },
						{ title: '옵션2', key: 'productOption2', size: 10 },
						{ title: '타입', key: 'productDeliveryType', size: 6 },
						{ title: '협찬 여부', key: 'influencerSelectable', size: 4 },
					],
					prependItem: [],
					tbClass: 'w-11/12 mx-auto',
					getChildData(targetProductGroup, data) {
						//==childChangePage. 여기서는 페이지 변경이 없기 때문에 이걸로 명칭 변경
						//협찬품 리스트를 조회한다

						postApi('/dealSelect/dealRestrictedDateList', {
							isExcel: false,
							isWholeDate: true,
							searchKey: getSearchKey('productGroupIdx', targetProductGroup.productGroupIdx),
							isExcludeEnded: true,
							paging: getPaging(0, 100),
						}).then(res => {
							ths.restrictedDate = res.list.map(v => ({
								startDate: v.startDate.substr(0, 10),
								endDate: v.endDate.substr(0, 10),
							}))
						})
						return postApi('/productGroupSelect/productOptionList', data).then(res => {
							targetProductGroup.child.title = `${targetProductGroup.brandName} ${targetProductGroup.productGroupName} 협찬품 선택`
							const list = [
								...copyVar(ths.productGroup.child.prependItem),
								...res.map(v => {
									v.idx = v.seqNo
									v.productDeliveryType = ths.getProductOptionType(v.productDeliveryType)
									return v
								}),
							]

							targetProductGroup.child.res = getTempPaging(list, list.length)
							return res
						})
					},
					selectable: 1,
					selectedItem: '',
					selectItem(v) {
						//협찬품 선택
						productGroup.child.selectedItem = v
					},
					deleteSelectedItem() {
						productGroup.child.selectedItem = ''
					},
					nav: 'none',
				},
			}

		hottist.child.prependItem = [
			{
				idx: '신규 딜 진행',
				idxOpt: { colspan: hottist.child.inf.length },
			},
		]
		productGroup.child.prependItem = [
			{
				idx: -1,
				seqNo: 'empty',
				productNum: '협찬품 없음',
				productNumOpt: { colspan: productGroup.child.inf.length },
			},
			{
				idx: -2,
				seqNo: 'selection',
				productNum: '협찬품 선택중',
				productNumOpt: { colspan: productGroup.child.inf.length },
			},
		]

		return {
			isSend: false,
			isEdit: this.idx != '',
			hottist,
			productGroup,
			startDate: '',
			endDate: '',

			dealTypeValue: null,
			chargeAdd: 0,
			restrictedDate: [],
		}
	},
	methods: {
		getProductType(productType = 1) {
			if (!isNaN(productType)) productType *= 1
			switch (productType) {
				case 1:
				default:
					return '일반'
				case '일반':
					return 1
				case 2:
					return '해외직구'
				case '해외직구':
					return 2
				case 3:
					return 'BS렌탈'
				case 'BS렌탈':
					return 3
				case 4:
					return '렌탈+일반'
				case '렌탈+일반':
					return 4
				case 5:
					return '주류'
				case '주류':
					return 5
			}
		},
		getProductOptionType(productOptionType = 1) {
			if (!isNaN(productOptionType)) productOptionType *= 1
			switch (productOptionType) {
				case 1:
				default:
					return '일반'
				case '일반':
					return 1
				case 2:
					return '렌탈(배송)'
				case '렌탈(배송)':
					return 2
				case 3:
					return '렌탈(설치)'
				case '렌탈(설치)':
					return 3
			}
		},
		addManualDeal(isTemporary = false) {
			//임시 저장하기, 바로 등록하기, 내용 수정하기 모두 여기를 탄다
			//유효성 검사
			const chargeAdd = num(this.chargeAdd),
				func = isTemporary => {
					const hottist = this.hottist.selectedItem,
						productGroup = this.productGroup.selectedItem,
						product = this.productGroup.child.selectedItem,
						error = []

					if (!hottist) error.push('핫티스트를 선택해주세요')

					if (!productGroup) error.push('상품 그룹을 선택해주세요')

					if (!product) error.push('협찬품을 선택해주세요')
					else if (!isTemporary && product.seqNo == 'selection')
						error.push('협찬품 선택중인 상태로는 바로 등록할 수 없습니다')

					if (this.startDate && this.endDate) {
						if (this.startDate > this.endDate) error.push('시작일이 종료일보다 늦습니다')
					} else error.push('시작일 또는 종료일을 선택해주세요')

					if (error.length) {
						alert.w(error)
						return
					}

					const startDate = getDateValue(this.startDate),
						endDate = getDateValue(this.endDate, false),
						baseData = {
							memberSeq: hottist.seq,
							startDate,
							endDate,
							isAutoEncore: false,
							//자동 앵콜 무조건 off
							productGroupIdx: productGroup.productGroupIdx,
							productOptionSeq: product.seqNo.toString(),
						},
						editData = {
							index: this.$route.params.idx,
							...baseData,
							adminSeq: layout.user.seqNo,
						}
					//유효성 검사 및 서버에 보낼 데이터 채우기
					if (this.isEdit && isTemporary)
						//임시저장 딜 수정하기
						putApi('/dealHandle/updateTemporaryDeal', editData, true, false).then(result => {
							if (result.data.hasSideFailure) alert.w(result.message)
							else alert.s('임시 저장 딜 수정이 완료 되었습니다.')
						})
					else {
						//중복검사 시작
						postApi('/dealSelect/isDuplicatedManualDeal', [
							{
								hottId: hottist.hottId,
								startDate,
								productGroupIdx: productGroup.productGroupIdx,
							},
						]).then(dup => {
							const dealInsertFunc = () => {
								//딜 등록 시작
								const data = {
									isComplete: isTemporary ? 'T' : 'O',
									...baseData,
									adminSeq: layout.user.seqNo,
								}
								if (!isTemporary) {
									data.chargeAdd = chargeAdd
									data.isSupportAdvertisement = false
									//광고 지원 여부 무조건 off
								}

								if (!this.isEdit) {
									const func = () => {
										if (this.isSend) return
										this.isSend = true
										postApi('/dealHandle/insertManualDeal', data, true, false).then(result => {
											const res = result.data
											if (!isTemporary)
												layout.copyText(
													`${dealHost}/${res.dealNum}`,
													`${res.dealNum} 딜로 생성 완료되었습니다. 해당 딜의 링크가 클립보드에 복사되었습니다.`
												)
											else alert.s('딜이 임시저장되었습니다.')
											if (res.hasSideFailure) alert.w(result.message)
											//if (this.isEdit) this.$route.go(-1)
											this.isSend = false
										})
									}
									isRestrictedDate([baseData]).then(res => {
										if (res)
											confirm(
												'딜 불가기간과 겹치는 날짜가 있습니다.\r\n그래도 딜을 생성하시겠습니까?',
												func
											)
										else func()
									})
								} else {
									//수정 상태에서 바로 등록하면 수정하기를 먼저 하고
									putApi('/dealHandle/updateTemporaryDeal', editData).then(() => {
										if (this.isSend) return
										this.isSend = true
										this.acceptTemporaryDeal(
											[{ idx: this.$route.params.idx, ...baseData }],
											true,
											isSuccess => {
												if (isSuccess) ths.isEdit = false
												this.isSend = false
											}
										)
									})
								}
							}

							let isPass = true
							if (dup.length)
								//중복된 항목이 있을 때 등록할지 물어보는 confirm
								confirm(
									`${dup[0].message}\r\n-딜 번호 : ${
										dup[0].dealNum
									}\r\n-일정 : ${dup[0].startDate.substr(5, 5)} ~ ${dup[0].endDate.substr(
										5,
										5
									)}\r\n-협찬품 : ${dup[0].productOptionName}\r\n※ 앵콜딜을 ${
										isTemporary ? '임시저장' : '바로등록'
									} 하시겠습니까?`,
									dealInsertFunc
								)
							else dealInsertFunc()
						})
					}
				}

			if (isTemporary && chargeAdd)
				confirm('딜 수수료 값이 변경되어, 바로 등록만 가능합니다. 해당 딜을 바로 등록하시겠습니까?', () => {
					func(false)
				})
			else func(isTemporary)
		},
		getTemporaryBtnText() {
			return this.isEdit ? '내용 수정하기' : '임시저장하기'
		},
		editPageInit() {
			//수동딜 수정으로 들어왔을 때 초기화 루틴. 뷰는 같은 컴포넌트에 주소만 바뀌는 경우 따로 watch 처리해줘야 하며, created를 다시 타지 않기 때문에 따로 함수로 뽑아냄
			const dealIdx = this.$route.params.idx
			if (dealIdx) {
				//넘어온 idx를 이용해서 값들 조회
				postApi('/dealSelect/manualDealList', {
					searchKey: [{ fieldName: 'dealIdx', andOr: 'and', operation: 'EQUAL', value: dealIdx }],
					paging: getPaging(),
				}).then(res => {
					res = res.list
					if (!res.length) {
						alert.w('조회된 결과가 없습니다')
						this.$router.go(-1)
						return
					}

					res = res[0]
					if (res.isComplete != 'T') {
						alert.w('임시 저장인 딜이 아닙니다')
						this.$router.go(-1)
						return
					}

					//서버에서 받은 데이터를 기반으로 핫티스트, 상품그룹, 협찬품 검색 후 딜 시작일, 종료일 채워 넣기
					this.hottist.primarySearch(res.hottId)
					this.productGroup.primarySearch(res.productGroupIdx, res.productOptionSeq)
					this.startDate = new Date(res.startDate.substr(0, 10))
					this.endDate = new Date(res.endDate.substr(0, 10))
				})
			} else {
				alert.w('딜 신청 idx가 잘못되었습니다')
				this.$router.go(-1)
			}
		},
		checkEncore() {
			const hottist = this.hottist.selectedItem,
				productGroup = this.productGroup.selectedItem
			if (hottist && productGroup) {
				const data = {
					hottId: hottist.hottId,
					productGroupIdx: productGroup.productGroupIdx,
				}

				data.dealTypeValue = this.dealTypeValue
				postApi('/dealSelect/dealBenefitInfo', data).then(res => {
					if (res) {
						this.chargeAdd = numFormat(res.chargeAdd)
						this.isSupportAdvertisement = false
						//광고 지원 여부 무조건 off
					}
				})
			}
		},
	},
	mounted() {
		ths = this
		//수동딜 수정으로 들어왔을 때 처리
		if (this.isEdit) this.editPageInit()
	},
	computed: {
		restrictedDateList() {
			if (this.productGroup.selectedItem._showDetails && this.restrictedDate.length) return this.restrictedDate
			return []
		},
	},
	watch: {
		$route: {
			//같은 컴포넌트면서 주소가 바뀐다면 변경되는 내용 바꿔줘야 한다
			handler() {
				this.isEdit = this.idx != ''
				if (this.isEdit) this.editPageInit()
			},
		},
	},
}
</script>

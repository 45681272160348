export default {
	methods: {
		getFailedMsg(res, isUnity = false) {
			//서버에서 내려준 일괄 등록/삭제 실패에 대한 메시지를 alert 형태에 맞게 가공
			let msg = ''
			res.failure.map(v => {
				if (v.list.length) {
					msg += '-  ' + v.message
					if (!isUnity) msg += ' : ' + v.list.join(', ')
					msg += '\r\n'
				}
			})
			return msg
		},
		getSideFailedMsg(res, isUnity = false) {
			let msg = ''
			if (res.sideFailure) {
				res.sideFailure.map(v => {
					if (v.list.length) {
						msg += '-  ' + v.message
						if (!isUnity) msg += ' : ' + v.list.join(', ')
						msg += '\r\n'
					}
				})
			}
			return msg
		},
		acceptTemporaryDeal(item, isUnity, afterFunc) {
			//임시저장 딜 등록 최종 단계, item: [{}], isUnity: boolean
			if (item.length) {
				const func = () => {
					postApi(
						'/dealHandle/acceptTemporaryDeals',
						{
							dealList: item.map(v => v.idx),
							adminSeq: layout.user.seqNo,
						},
						true,
						false
					).then(result => {
						const res = result.data,
							success = res && res.success,
							isSuccess = success && success.length == item.length
						if (isSuccess) {
							//모두 성공했을 때
							if (!isUnity) alert.s('딜 생성 완료되었습니다')
							else
								layout.copyText(
									`${dealHost}/${success[0].dealNum}`,
									`${success[0].dealNum} 딜로 생성 완료되었습니다.\r\n해당 딜의 링크가 클립보드에 복사되었습니다.`
								)
						} else {
							const msg = this.getFailedMsg(res, isUnity)
							if (!isUnity) {
								alert.w(
									'딜 등록 완료되었으며, 아래 사유에 따라 일부 딜이 정상 등록되지 않았습니다.\r\n' +
										msg +
										'\r\n확인 후 해당 딜은 다시 시도해주세요. (번호는 seq 기준입니다.)'
								)
							} else
								alert.w(
									'해당 딜은 아래와 같은 사유로 등록이 불가능합니다.\r\n' +
										msg.replace(/<br>/gi, '\r\n')
								)
						}
						if (typeof this.changeResState == 'function') this.changeResState(success, false)

						const memoFailedMsg = this.getSideFailedMsg(res, isUnity)
						if (memoFailedMsg) alert.w(memoFailedMsg)
						if (typeof afterFunc == 'function') afterFunc(isSuccess)
						return isSuccess
					})
				}
				isRestrictedDate(item, isUnity).then(res => {
					if (res) {
						let msg = isUnity
							? '딜 불가기간과 겹치는 날짜가 있습니다.\r\n그래도 딜을 생성하시겠습니까?'
							: '아래 딜의 일정에 딜 불가기간과 겹치는 날짜가 있습니다.\r\n그래도 임시저장 딜을 일괄 생성하시겠습니까?\r\n' +
							  res
						confirm(msg, func)
					} else func()
				})
			} else alert.w('선택된 항목이 없습니다')
		},
	},
}
